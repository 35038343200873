import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Input, Form, Row, Col, Button, Tabs } from "antd";
import { toast } from "react-toastify";
import { postRequest } from "../../api/http";
import userServiceApi from "../../api/userServiceApi";
import { LoadingView } from "../common";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import "./ChangePassword.scss";

function ChangePassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    if (values.newPassword === values.confirmPwd) {
      delete values.confirmPwd;
      try {
        setLoading(true);
        const response = await postRequest(
          userServiceApi.changePassword(),
          values
        );
        if (response === "Success") {
          setLoading(false);
          toast.success("Password Updated Successfuly");
          navigate("/");
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.error);
      }
    } else {
      setLoading(false);
      toast.error("Password and Confirm Password does not match");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  if (loading) return <LoadingView message="Loading..." />;

  return (
    <div className="tabContainer" style={{ minHeight: "fit-content" }}>
      <div className="page-header">
        <div className="backbtn-container">
          <img src={backArrow} alt="" onClick={() => navigate('/')} />
          <p>Change Password</p>
        </div>
      </div>
      {/* <Tabs>
        <Tabs.TabPane tab="Change Password" key="tab1"> */}
      <div className="change-pwd-form-container">
        <Row
          className="rowBody"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col className="gutter-row tabContent" span={19}>
            <Form
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 9 }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {/* <div className="changePwdHeading">Change Password</div> */}
              <div className="changePwdBody customBody">
                <Col className="gutter-row">
                  <Form.Item
                    label="Old Password"
                    name="currentPassword"
                    rules={[
                      { required: true, message: 'Please input your old password!' },
                    ]}                  >
                    <Input.Password placeholder="Old Password"
                      iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row">
                  <Form.Item
                    label="New Password"
                    name="newPassword"
                    hasFeedback
                    rules={[
                      { required: true, message: 'Please input your new password!' },
                      { min: 8, message: 'Password must be at least 8 characters long!' },
                      {
                        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                        message: 'Password must include uppercase, lowercase, number, and special character!',
                      },
                    ]}                >
                    <Input.Password placeholder="New Password" iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row">
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPwd"
                    hasFeedback
                    dependencies={['newPassword']}  // Add dependencies to revalidate when 'newPassword' changes
                    rules={[
                      { required: true, message: 'Please input your new password!' },
                      { min: 8, message: 'Password must be at least 8 characters long!' },
                      {
                        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                        message: 'Password must include uppercase, lowercase, number, and special character!',
                      }, ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Passwords do not match!'));
                        },
                      }),
                    ]} >
                    <Input.Password placeholder="Confirm Password" iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                    />
                  </Form.Item>
                </Col>
                <Col >
                  <Form.Item label=" " colon={false} >
                    <div
                      className="pwdUpdateBtn"
                      style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
                    >
                      <Button
                        className="cancelBtn"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        Cancel
                      </Button>
                      <Button className="greenBtn" htmlType="submit" onClick={() => form.submit()}>
                        Update
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </div>
            </Form>
          </Col>
        </Row>

      </div>
      {/* </Tabs.TabPane>
      </Tabs> */}
    </div >
  );
}

export default ChangePassword;
