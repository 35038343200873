import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Row, Col, Button } from "antd";
import userServiceApi from "../../api/userServiceApi";
import Logo from "../../../src/assets/images/PoweredNew.png";
import { toast } from "react-toastify";
import "./ResetPassword.scss"

export default function ResetPasswordForm() {
  const param = useParams();
  const token = param.token;

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const textInput = useRef(null);
  const [form] = Form.useForm();

  const handleReset = async (values) => {
    if (values && values.newPassword && values.confirmPassword) {
      if (values.newPassword !== values.confirmPassword) {
        toast.error("Passwords does not match");
      } else {
        setLoading(true);
        try {
          const response = await fetch(userServiceApi.resetPassword(token), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newPassword: values.newPassword }),
          });
          if (response.ok) {
            const res = await response.text()
            navigate(`/login/${res}`);
            toast.success("Password successfully created");
          }
          else {
            const res = await response.json()
            toast.error(res.message)
          }
        } catch (error) {
          console.log('Error in reset password:', error);
          if (error.message) {
            toast.error(error.message)
          }
        }
        finally {
          setLoading(false);
        }
      }
    }
  };
  return (
    <div className="reset-password-container">
      {/* <Banner /> */}
      <div className="headerBannerRow">
        <div className="headerBannerCol">
          <div>
            <img
              className="Reset-headerLogo"
              src={Logo}
              alt=""
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "20px" }} >
        <h2 style={{ fontSize: '20px', fontWeight: 600 }} > Reset Password </h2>
        <Row style={{ padding: "20px" }} className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row tabContent" span={19}>
            <Form
              name="control-hooks"
              className="organizationForm"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 9,
              }}
              form={form}
              autoComplete="off"
              onFinish={handleReset}
            >
              <Form.Item
                name="newPassword"
                label="New Password"
                hasFeedback
                rules={[
                  { required: true, message: 'Please input your new password!' },
                  { min: 8, message: 'Password must be at least 8 characters long!' },
                  {
                    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                    message: 'Password must include uppercase, lowercase, number, and special character!',
                  },
                ]}
              >
                <Input.Password required={true} type="password" placeholder="New Password" />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={['newPassword']}  // Add dependencies to revalidate when 'newPassword' changes
                hasFeedback  // Provides feedback to the user as they type
                rules={[
                  { required: true, message: 'Please input your new password!' },
                  { min: 8, message: 'Password must be at least 8 characters long!' },
                  {
                    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                    message: 'Password must include uppercase, lowercase, number, and special character!',
                  }, ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Passwords do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password required={true} type="password" placeholder="Confirm Password" />
              </Form.Item>

              <Form.Item label=" " colon={false} >
                <div className="form-btn-container" >
                  <Button type="primary" loading={loading} className="form-save-btn submitBtn greenBtn" onClick={() => form.submit()}>
                    Submit
                  </Button>
                </div>
              </Form.Item>

            </Form>
          </Col>
        </Row>
      </div>
      <div className="footer d-flex" style={{ paddingTop: "2vh" }}>
        <div className="footer-content" onClick={() => window.open("https://www.connectsu.co.uk/", '_blank')} style={{ cursor: "pointer" }}>
          {/* <span>Powered by</span> */}
          <div className="footer-logo">
            <img
              src={Logo}
              alt="company logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
