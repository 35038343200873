export const TAB_KEYS = {
  corousel: "corousel",
  aboutUs: "about-us",
  product: "product",
  service: "service",
  contract: "contract",
  workExperience: "work-experience",
  contactUs: "contact-us",
  apprenticeship: "PART_TIME",
  vacancy: "FULL_TIME",
  whyWorkForUs: "why-work",
  teamsAndRoles: "teams-and-roles",
  courses: "courses",
  businessCard: "businessCard",
  news: 'news_tab',
  press: 'press'
  // testTab: 'testTab'
};

export const vacancyKeys = ["PART_TIME", "FULL_TIME"];

export const contract = "contract";

export const contactUSkeys = {
  sales: "SALES",
  hr: "HUMAN_RESOURCE",
  eow: "EXPERIENCES_OF_WORK",
  reviews: "REVIEWS",
  socialMedia: "SOCIAL_MEDIA",
  address: "ADDRESS"
};

export const contactEnumsArr = Object.values(contactUSkeys).map((item) => item)

export const aboutUSkeys = {
  About: "ABOUT_US",
  "Our Story": "OUR_STORY",
  "Our Team": "OUR_TEAM",
  "Our Client": "OUR_CLIENT",
  "Accreditation": "ACCREDITATIONS_AWARDS",
};
export const aboutUsEnumsArr = Object.values(aboutUSkeys).map((item) => item)

export const dateFormat = "DD/MM/YYYY";

export const dateTimeFormat = "DD/MM/YYYY hh:mm:ss";
