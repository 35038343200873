import React, { useEffect } from "react";
import { useStore } from "../../store";
import { Button, Form, Modal, Switch, Spin } from "antd";
import "./settings.scss";
import { useState } from "react";
import { useSettings } from "../../custom-hooks/useSettings";

// name -> name to display   
// key -> key from settings api result
// key2 -> key in PUT request to update pro settings 
const allSettingsItem = [
    {
        name: "Products",
        key: "products",
        key2: 'PRODUCT'
    },
    {
        name: "Services",
        key: "services",
        key2: 'SERVICE'
    },
    {
        name: "Vacancies",
        key: "vacancies",
        key2: 'VACANCY'
    },
    {
        name: "Apprenticeships",
        key: "apprenticeships",
        key2: 'APPRENTICESHIP'
    },
    {
        name: "Teams and roles",
        key: "teamsAndRoles",
        key2: 'TEAMS_AND_ROLES'
    },
    {
        name: "Courses",
        key: "courses",
        key2: 'COURSES'
    },
    {
        name: "Experience of work",
        key: "experienceOfWork",
        key2: 'WORK_EXPERIENCE'
    },
    {
        name: "Why work for us",
        key: "whyWork",
        key2: 'WHY_WORK'
    },
    {
        name: "Contracts",
        key: "contracts",
        key2: 'CONTRACTS'

    },
    {
        name: 'Blog',
        key: 'newsTab',
        key2: 'NEWS_TAB'
    },
    {
        name: 'In the press',
        key: 'press',
        key2: 'PRESS'
    }
];

export default function MicrositeSettingsCopy({ activeKey, open, closeSettings }) {
    const { updateSettings, settingsUpdateLoader, getSettings } = useSettings();
    const { settings } = useStore();

    const [checker, setChecker] = useState();
    const [allLoader, setAllLoader] = useState(true);
    const [allChecker, setAllChecker] = useState(false);

    const settingsItem = allSettingsItem.filter((ele) => ele.key in settings)

    const updatedState = () => {
        const temp = settingsItem.map(item => ({
            ...item,
            switchStatus: settings[item.key] || false
        }));

        setChecker(temp)
    }

    useState(() => {
        getSettings(activeKey)
    }, [])

    useEffect(() => {
        setAllLoader(true);
        updatedState();
        setAllLoader(false);
    }, [settings]);

    useEffect(() => {
        let anyTrue = true;
        for (let item in checker) {
            if (!checker[item].switchStatus) {
                anyTrue = false
                break;
            }
        }
        setAllChecker(anyTrue);
    }, [checker])


    const handleChecker = (isChecked, key) => {
        const updatedChecker = checker.map(item => {
            if (item.key === key) {
                return {
                    ...item,
                    switchStatus: isChecked
                };
            }
            return item;
        });
        setChecker(updatedChecker);
    }

    const handleAllToggle = (isChecked) => {
        setAllLoader(true);
        const temp = settingsItem.map(item => ({
            ...item,
            switchStatus: isChecked
        }));

        setChecker(temp);
        setAllLoader(false)
    }



    const submit = () => {
        const dataObject = {};
        checker.forEach(item => {
            dataObject[item.key2] = item.switchStatus;
        });
        // console.log('payload', dataObject)
        updateSettings(activeKey, { settings: dataObject });
        closeSettings();
    };

    return (
        <div className="">
            <Modal open={open} footer={null} width="400px" style={{ top: "40px" }} closable={false} onCancel={closeSettings}>
                {(allLoader || settingsUpdateLoader) ? <div className="data-loader">
                    <Spin tip="Loading" size="large" />
                </div> :
                    <>
                        <div className="header">
                            <div className="ms-settings-header settings-header"> Microsite Pro Settings</div>
                            <div className="all-switch">
                                <Switch
                                    className="siteSetting-switch"
                                    checked={allChecker}
                                    onChange={(e) => handleAllToggle(e)}
                                />
                            </div>
                        </div>
                        {checker && checker?.length > 0 ?
                            <>
                                <div className="ms-settings">
                                    {checker && checker.map((d, i) => {
                                        return (
                                            <div className="ms-settings-row">
                                                <div className="ms-settings-label">{d?.name}</div>
                                                <div className="ms-settings-switch">
                                                    <Switch
                                                        className="siteSetting-switch"
                                                        // defaultChecked={
                                                        //     checker && checker[d.key] ? checker[d.key] : false
                                                        // } 
                                                        checked={d?.switchStatus}
                                                        // defaultChecked={d.switchStatus}
                                                        onChange={(e) => handleChecker(e, d?.key)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="ms-settings-footer">
                                    <div className="ms-settings-footer-sub">
                                        <Button className="cancelBtn" onClick={closeSettings}>
                                            Cancel
                                        </Button>
                                        <Button
                                            className="greenBtn"
                                            onClick={submit}
                                            loading={settingsUpdateLoader}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </>
                            : <>
                                <div>Spinning</div>
                            </>
                        }
                    </>
                }
            </Modal>
        </div>
    );
}