import React, { useState, useEffect, useRef } from "react";
import {
    Avatar,
    Button,
    Input,
    Form,
    Select,
    Row,
    Col,
    Spin,
    Tooltip,

} from "antd";
import {
    PlusOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    LinkOutlined
} from "@ant-design/icons";
import UserIcon from "../../../../assets/images/icons/userLogo.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../store";
import { TAB_KEYS } from "../../../../config";
import { useTab } from "../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../utils";
import ReactPlayer from "react-player/youtube";
import products from "../../../organisations/json/products.json";

import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill';

const { TextArea } = Input;
const NewsTab = ({ dataLoader, form, getSingleTabData, loader: formDataLoader }) => {
    const { state } = useLocation();

    const tabKey = state;
    const { tabData } = useStore();
    const whichTab = TAB_KEYS.news;
    const { deleteSingleItem, postLoader } = useTab();
    const { updateImage, updateFile } = useConnectsuForm();
    const [loader, setLoader] = useState(formDataLoader || true);
    const [formChanged, setFormChanged] = useState(false);
    const { updatedValues } = useStore();
    const [items, setItems] = useState(products.map((item) => item.name));

    const ReactQuillModules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' }
            ],
            ['link', 'image']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
        }
    }

    useEffect(() => {
        if (tabData[whichTab]) {
            tabData[whichTab].forEach((item) => {
                if (item?.title && !items.includes(item.title)) {
                    items.push(item.title);
                }
            });
        }
    }, [tabData[whichTab]]);


    useEffect(() => {
        localStorage.setItem("tab", "NEWS");
    }, []);

    useEffect(() => {
        const isNotEmpty = Object.keys(updatedValues).length > 0;

        setFormChanged(isNotEmpty);
    }, [Object.keys(updatedValues).length]);

    const renderMedia = (dataItem) => {
        const commonStyles = {
            width: "350px",
            height: "250px",
            display: "flex",
            justifyContent: "center",
        };

        if (
            dataItem?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
            dataItem.mediaLink.url
        ) {
            return (
                <div className="iframe-comp" style={commonStyles}>
                    <ReactPlayer
                        controls={true}
                        url={dataItem.mediaLink.url}
                        width="100%"
                        height="100%"
                    />
                </div>
            );
        } else if (
            dataItem?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
            dataItem.mediaLink.url
        ) {
            return (
                <div className="iframe-comp" style={commonStyles}>
                    <iframe
                        src={dataItem.mediaLink.url}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        width="100%"
                        height="100%"
                        style={{ backgroundColor: "black" }}
                    />
                </div>
            );
        } else if (
            dataItem?.mediaLink?.mediaType === "IMAGE" &&
            dataItem.mediaLink.url
        ) {
            return (
                <div className="iframe-comp" style={commonStyles}>
                    <img
                        src={dataItem?.mediaLink?.url}
                        alt=""
                        className="c1 carousel-image"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                </div>
            );
        }

        return null;
    };

    const findValue = (i, key) => {
        return getFormFieldValue(form, whichTab, i, key);
    };
    const SelectChangeHandler = (e, i) => {
        form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
    };



    useEffect(() => {
        form.resetFields();
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    }, [tabData[whichTab]]);

    if (dataLoader || postLoader || loader) {
        return <div className="data-loader">
            <Spin tip="Loading" size="large" />
        </div>
    } else {
        return <div>
            <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {tabKey === whichTab ? (
                    <Col className="gutter-row tabContent" span={24}>
                        <Form.List
                            name={whichTab}
                            initialValue={
                                tabData &&
                                    tabData[whichTab] &&
                                    tabData[whichTab].length > 0
                                    ? tabData[whichTab]
                                    : [{
                                        "title": "",
                                        "description": "",
                                        "type": "news-tab",
                                        "media": {},
                                        "urlLink": ''
                                    }]
                            }
                        >
                            {(fields, { add, remove }) => {
                                return (<>
                                    {fields.map(({ key, name, ...restField }, i) => {
                                        return (
                                            <div className="card-item">
                                                <Col className="gutter-row tabContent" span={16} style={{ paddingRight: "25px" }}>
                                                    <div className="d-flex" >
                                                        <Form.Item
                                                            label="Title"
                                                            className="w-100"
                                                            {...restField}
                                                            labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                                            name={[name, "title"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Article title is required",
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Enter article title" />
                                                        </Form.Item>
                                                        <div className="remove-btn" style={{ position: 'relative' }} >
                                                            <DeleteOutlined
                                                                title="Delete article"
                                                                onClick={() =>
                                                                    deleteSingleItem(
                                                                        whichTab,
                                                                        form.getFieldValue([
                                                                            whichTab,
                                                                            i,
                                                                            "id",
                                                                        ]),
                                                                        form
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <Form.Item
                                                        label="Description"
                                                        {...restField}
                                                        labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                                        name={[name, "description"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Article description is required",
                                                                validator: (_, value) => {
                                                                    const description = form.getFieldValue(tabKey)[i]?.description || '';
                                                                    if (!description || description.trim() === '<p><br></p>') {
                                                                        return Promise.reject(new Error('Blog description is required'));
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                        validateStatus={
                                                            !form.getFieldValue(tabKey)[i]?.description ||
                                                                form.getFieldValue(tabKey)[i]?.description.trim() === '<p><br></p>'
                                                                ? 'error'
                                                                : 'success'
                                                        } >
                                                        {/* <TextArea
                                                                    placeholder="Enter News Description"
                                                                    rows={5}
                                                                /> */}


                                                        <ReactQuill value={form.getFieldValue(tabKey)[i]?.description || ''}
                                                            onChange={(e) => {
                                                                const updatedFieldValues = {
                                                                    ...form.getFieldValue(tabKey),
                                                                    [i]: {
                                                                        ...form.getFieldValue(tabKey)[i],
                                                                        description: e,
                                                                    },
                                                                };
                                                                let modifiedFormData = Object.keys(updatedFieldValues).map((data, i) => updatedFieldValues[data])
                                                                form.setFieldValue(tabKey, modifiedFormData);
                                                            }}
                                                            modules={ReactQuillModules}
                                                        />


                                                    </Form.Item>
                                                    <div className="d-flex">
                                                        <Form.Item
                                                            {...restField}
                                                            className="w-100"
                                                            name={[name, "mediaLink", "mediaType"]}
                                                            label="Video or Image"
                                                            labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                                        // rules={[
                                                        //   {
                                                        //     required: true,
                                                        //     message: "Select any option",
                                                        //   },
                                                        // ]}
                                                        >
                                                            <Select
                                                                name={[name, "mediaLink", "mediaType"]}
                                                                onChange={(e) => SelectChangeHandler(e, i)}
                                                                placeholder="Select Option"
                                                            >
                                                                <Select.Option value="VIMEO_MEDIA">
                                                                    Add Vimeo Link
                                                                </Select.Option>
                                                                <Select.Option value="SOCIAL_MEDIA">
                                                                    Add Youtube Link
                                                                </Select.Option>
                                                                <Select.Option value="IMAGE">
                                                                    Upload Image
                                                                </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <div className="remove-btn"  >
                                                                <Tooltip
                                                                    title={
                                                                        "Upload Video link or image with size lesser than 2.5MB"
                                                                    }
                                                                >
                                                                    <QuestionCircleOutlined />
                                                                </Tooltip>
                                                            </div>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Form.Item
                                                            label="Article external Link"
                                                            className="w-100"
                                                            {...restField}
                                                            labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                                            name={[name, "urlLink"]}
                                                            rules={[{
                                                                type: 'url',
                                                                message: 'Please enter a valid URL',
                                                            }]}
                                                        >
                                                            <Input placeholder="Article external Link" />
                                                        </Form.Item>
                                                        {tabData[whichTab]?.[i]?.urlLink && <Form.Item>
                                                            <div  >
                                                                <a className="remove-btn"
                                                                    target="_blank" href={tabData[whichTab]?.[i].urlLink}>
                                                                    <Tooltip
                                                                        title={
                                                                            "External link"
                                                                        }
                                                                    >
                                                                        <LinkOutlined />
                                                                    </Tooltip>
                                                                </a>
                                                            </div>
                                                        </Form.Item>}

                                                    </div>
                                                    {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                                                        <Form.Item
                                                            label="Youtube Link"
                                                            {...restField}
                                                            labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                                            name={[name, "mediaLink", "url"]}
                                                        // rules={[
                                                        //   {
                                                        //     required: true,
                                                        //     message: "Youtube link is required",
                                                        //   },
                                                        // ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                                                        <Form.Item
                                                            label="Vimeo Link"
                                                            labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                                            {...restField}
                                                            name={[name, "mediaLink", "url"]}
                                                        // rules={[
                                                        //   {
                                                        //     required: true,
                                                        //     message: "Vimeo link is required",
                                                        //   },
                                                        // ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    ) : findValue(i, "type") === "IMAGE" ? (
                                                        <Form.Item
                                                            {...restField}
                                                            label="Image"
                                                            colon={false}
                                                            name="media"
                                                            className="experienceImg"
                                                            labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                                        // rules={[
                                                        //   {
                                                        //     validator: (_, value) => {
                                                        //       if (findValue(i, "type") === "IMAGE") {
                                                        //         return findValue(i, "blob")
                                                        //           ? Promise.resolve()
                                                        //           : findValue(i, "url")
                                                        //             ? Promise.resolve()
                                                        //             : Promise.reject(
                                                        //               new Error("Image is required")
                                                        //             );
                                                        //       } else {
                                                        //         return Promise.resolve();
                                                        //       }
                                                        //     },
                                                        //   },
                                                        // ]}
                                                        >
                                                            {findValue(i, "blob") ? (
                                                                <Avatar
                                                                    src={URL.createObjectURL(
                                                                        findValue(i, "blob")
                                                                    )}
                                                                    alt=""
                                                                />
                                                            ) : findValue(i, "url") ? (
                                                                <Avatar src={findValue(i, "url")} alt="" />
                                                            ) : (
                                                                <Avatar src={UserIcon} />
                                                            )}

                                                            <label
                                                                name="file"
                                                                className="fileUploadBtn greenBtn"
                                                            >
                                                                {" "}
                                                                Choose File
                                                                <input
                                                                    accept="image/png, image/gif, image/jpeg"
                                                                    type="file"
                                                                    // required={true}
                                                                    onChange={(e) =>
                                                                        updateImage(e, i, form, whichTab)
                                                                    }
                                                                />
                                                            </label>
                                                        </Form.Item>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <Form.Item
                                                        className="w-100"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "end",
                                                        }}
                                                    >
                                                        {formChanged ? (
                                                            <Button
                                                                className="saveBtn greenBtn"
                                                                onClick={() => {
                                                                    form.submit().then(() => {
                                                                        setFormChanged(false);
                                                                    }).catch((errorInfo) => {
                                                                        console.error('Form submission failed:', errorInfo);
                                                                    });
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className="saveBtn greenBtn"
                                                                style={{
                                                                    opacity: "0.5",
                                                                    pointerEvents: "none",
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col className="gutter-row tabContent" span={8}>
                                                    <div>{tabData[whichTab]?.[i] ? renderMedia(tabData[whichTab][i]) : null}</div>

                                                </Col>
                                            </div>)
                                    }

                                    )}

                                    <div className="inputBody">
                                        <label></label>
                                        <div style={{ textAlign: "right" }}>
                                            {fields && fields.length <= 1 ? (
                                                <></>
                                            ) : (
                                                <Button
                                                    className="cancelBtn"
                                                    type="button"
                                                    onClick={() => {
                                                        const itemsToRemove = [];
                                                        fields.forEach((f, i) => {
                                                            if (!findValue(i, "id") && i !== 0) {
                                                                itemsToRemove.push(f.name);
                                                            }
                                                        });
                                                        if (itemsToRemove.length > 0) {
                                                            remove(itemsToRemove);
                                                        }
                                                    }}
                                                >
                                                    Clear
                                                </Button>
                                            )}
                                            <Button className="greenBtn" onClick={() => add()}>
                                                Add More
                                                <PlusOutlined />
                                            </Button>
                                        </div>
                                    </div>
                                </>)
                            }

                            }
                        </Form.List>
                    </Col>
                ) : (
                    <></>
                )
                }
            </Row>
        </div>
    }
}

export default NewsTab