import React, { useState, useEffect } from "react";
import { Tabs, Button, Form, Modal } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "./index.scss";
import Carousel from "./tabs/carousel";
import AboutUs from "./tabs/about-us";
import ProductComponent from "./tabs/product";
import ServiceComponent from "./tabs/service";
import ContractComponent from "./tabs/contract";
import ApprenticesComponent from "./tabs/apprenticeship";
import VacancyComponent from "./tabs/vacancy";
import ContactUsComponent from "./tabs/contact-us";
import WhyWorkForUs from "./tabs/WhyWorkforus";
import TeamsAndRoles from "./tabs/TeamsRoles";
import BusinessCard from "./tabs/business-card";
import Courses from "./tabs/courses";
import { useTab } from "../../../custom-hooks/useTab";
import { aboutUsEnumsArr, aboutUSkeys, contactEnumsArr, contactUSkeys, TAB_KEYS } from "../../../config";
import { useStore } from "../../../store";
import { formatData } from "../../../utils/formatData";
import { setUpdatedValues } from "../../../store/actions";
import { useSettings } from "../../../custom-hooks/useSettings";
import WorkExperienceCopy from "./tabs/work-experience copy";
import NewsTab from "./tabs/NewsTab";
import PressTab from "./tabs/PressTab";

// tabName : to display tab name
// tabKey : tab key for internal usage
// settingsKey : key to map from and to backend
const tabList = [
  {
    tabName: "Business Card",
    component: (params) => <BusinessCard {...params} />,
    tabKey: TAB_KEYS.businessCard,
    settingsKey: "business-card",
  },
  {
    tabName: "Home carousel",
    component: (params) => <Carousel {...params} />,
    tabKey: TAB_KEYS.corousel,
    settingsKey: TAB_KEYS.corousel,
  },
  {
    tabName: "About Us",
    component: (params) => <AboutUs {...params} />,
    tabKey: TAB_KEYS.aboutUs,
    settingsKey: TAB_KEYS.corousel,
  },
  {
    tabName: "Products",
    component: (params) => <ProductComponent {...params} />,
    tabKey: TAB_KEYS.product,
    settingsKey: "products",
  },
  {
    tabName: "Services",
    component: (params) => <ServiceComponent {...params} />,
    tabKey: TAB_KEYS.service,
    settingsKey: "services",
  },
  {
    tabName: "Contracts",
    component: (params) => <ContractComponent {...params} />,
    tabKey: TAB_KEYS.contract,
    settingsKey: "contracts",
  },
  {
    tabName: "Contact Us",
    component: (params) => <ContactUsComponent {...params} />,
    tabKey: TAB_KEYS.contactUs,
    settingsKey: TAB_KEYS.corousel,
  },
  {
    tabName: "Apprenticeships",
    component: (params) => <ApprenticesComponent {...params} />,
    tabKey: TAB_KEYS.apprenticeship,
    settingsKey: "apprenticeships",
  },
  {
    tabName: "Vacancies",
    component: (params) => <VacancyComponent {...params} />,
    tabKey: TAB_KEYS.vacancy,
    settingsKey: "vacancies",
  },
  {
    tabName: "Courses",
    component: (params) => <Courses {...params} />,
    tabKey: TAB_KEYS.courses,
    settingsKey: "courses",
  },
  {
    tabName: "Why work for us",
    component: (params) => <WhyWorkForUs {...params} />,
    tabKey: TAB_KEYS.whyWorkForUs,
    settingsKey: "whyWork",
  },
  {
    tabName: "Our teams and roles",
    component: (params) => <TeamsAndRoles {...params} />,
    tabKey: TAB_KEYS.teamsAndRoles,
    settingsKey: "teamsAndRoles",
  },
  {
    tabName: "Experiences of work",
    component: (params) => <WorkExperienceCopy {...params} />,
    // component: (params) => <WorkExperience {...params} />,
    tabKey: TAB_KEYS.workExperience,
    settingsKey: "experienceOfWork",
  },
  {
    tabName: "Blog",
    component: (params) => <NewsTab {...params} />,
    tabKey: TAB_KEYS.news,
    settingsKey: "newsTab",
  },
  {
    tabName: "In The Press",
    component: (params) => <PressTab {...params} />,
    tabKey: TAB_KEYS.press,
    settingsKey: TAB_KEYS.press,
  },
];

function EditMicrosite({ micrositeId }) {
  const navigate = useNavigate();
  const { state } = useLocation();

  localStorage.setItem("micrositeId", micrositeId);

  const [form] = Form.useForm();

  const {
    getSingleTabData,
    dataLoader,
    callMicroServices,
    postLoader,
    getOptions,
  } = useTab();

  const { updatedValues, dispatch, settings } = useStore();
  const { getSettings } = useSettings();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextTabKey, setNextTabKey] = useState(null);

  const [activeKey, setActiveKey] = useState("");
  const [formChanged, setFormChanged] = useState(false);


  const [splitParams, setSplitParams] = useState([])
  const [tabId, setTabId] = useState('')
  const [tabIdLength, setTabIdLength] = useState(0)

  useEffect(() => {

    if (state.length > 0) {
      const tabId = state;
      setTabId(state)

      const tempSplitParams = tabId
        ? tabId?.split("/").filter(function (entry) {
          return entry.trim() != "";
        })
        : []

      setSplitParams(tempSplitParams)

      setTabIdLength(tempSplitParams.length)
    }
    else {
      navigate(`/${micrositeId}`, {
        state: `about-us/${aboutUSkeys.About}`,
      })
    }
  }, [state])


  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);

  useEffect(() => {
    let tabActiveKey = tabIdLength <= 1 ? tabId : splitParams[0];
    let selectedTab = tabList.find((tab) => tab.tabKey == tabActiveKey);
    setActiveKey(selectedTab?.tabKey);
    if (!settings[selectedTab?.settingsKey]) {
      handleTabs("about-us");
    }
  }, [
    settings?.businessCard,
    settings?.apprenticeships,
    settings?.experienceOfWork,
    settings?.teamsAndRoles,
    settings?.products,
    settings?.services,
    settings?.contracts,
    settings?.vacancies,
    settings?.whyWork,
  ]);

  const SubmitButton = ({ postLoader }) => {
    const getTabId = tabIdLength <= 1 ? tabId : splitParams[1];
    if (getTabId !== "work-experience" && getTabId !== "contract") {
      return (
        <div>
          {postLoader === true ? (
            <div>
              <Button className="saveBtn greenBtn">loading...</Button>
            </div>
          ) : formChanged ? (
            <Button
              className="saveBtn greenBtn"
              // htmlType="submit"
              onClick={() => {
                form.submit();
              }}
            >
              Save All
            </Button>
          ) : (
            <Button
              className="saveBtn greenBtn"
              style={{ opacity: "0.5", pointerEvents: "none" }}
            >
              Save All
            </Button>
          )}
        </div>
      );
    } else if (getTabId === "contract") {
      return (
        <div>
          {postLoader === true ? (
            <div>
              <Button className="saveBtn greenBtn">loading...</Button>
            </div>
          ) : formChanged ? (
            <Button
              className="saveBtn greenBtn"
              // htmlType="submit"
              onClick={() => {
                submit(form.getFieldValue());
              }}
            >
              Save All
            </Button>
          ) : (
            <Button
              className="saveBtn greenBtn"
              style={{ opacity: "0.5", pointerEvents: "none" }}
            >
              Save All
            </Button>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
    // return (
    //   <div>
    //     {postLoader === true ? (
    //       <div>
    //         <Button className="saveBtn greenBtn">loading...</Button>
    //       </div>
    //     ) : (
    //       <Button className="saveBtn greenBtn" htmlType="submit">
    //         Save
    //       </Button>
    //     )}
    //   </div>
    // );
  };

  useEffect(() => {
    if (tabId && tabId != " ") {
      if (tabIdLength === 1 && splitParams[0] === TAB_KEYS.contactUs) {
        navigate(`/${micrositeId}`, {
          state: `${splitParams[0]}/${contactUSkeys.sales}`,
        });
      }
      else if (tabIdLength === 1 && splitParams[0] === TAB_KEYS.aboutUs) {
        navigate(`/${micrositeId}`, {
          state: `${splitParams[0]}/${aboutUSkeys.About}`,
        });
      }
      else {
        navigate(`/${micrositeId}`, { state: tabId });
      }
    } else {
      navigate(`/${micrositeId}`, { state: `${TAB_KEYS.aboutUs}` });
    }
    getSettings(micrositeId);
  }, []);

  useEffect(() => {
    if (tabId && tabId != "") {
      getSingleTabData(tabIdLength <= 1 ? tabId : splitParams[1]);
    }
  }, [tabId]);

  useEffect(() => {
    getOptions(micrositeId, "PRODUCT");
    getOptions(micrositeId, "COURSES");
    getOptions(micrositeId, "TEAMS_AND_ROLES");
  }, []);

  const handleTabs = (tabKey) => {
    if (tabKey !== tabId) {
      const getTabId = tabIdLength <= 1 ? tabId : splitParams[1];
      const info = form.getFieldsValue(true);
      const formattedData = formatData(getTabId, info[getTabId], updatedValues);
      if (Object.keys(formattedData).length > 0) {
        setIsModalOpen(true);
        setNextTabKey(
          tabKey
        );
      } else {
        callNavigation(tabKey);

        let TabKey = tabKey
        if (tabKey === "SALES" ||
          tabKey === "HUMAN_RESOURCE" ||
          tabKey === "SOCIAL_MEDIA" ||
          tabKey === "EXPERIENCES_OF_WORK" ||
          tabKey === "REVIEWS" ||
          tabKey === "ADDRESS") { TabKey = "contact-us" }

        if (tabKey === 'ABOUT_US' ||
          tabKey === 'OUR_STORY' ||
          tabKey === 'OUR_TEAM' || tabKey === 'OUR_CLIENT' ||
          tabKey === 'ACCREDITATIONS_AWARDS') { TabKey = "about-us" }

        setActiveKey(TabKey);
      }
    }
  };


  const callNavigation = (key) => {
    if (contactEnumsArr.includes(key)) {
      navigate(`/${micrositeId}`, {
        state: `contact-us/${key}`,
      });
    }
    else if (aboutUsEnumsArr.includes(key)) {
      navigate(`/${micrositeId}`, {
        state: `about-us/${key}`,
      });
    }
    else if (key === TAB_KEYS.contactUs) {
      navigate(`/${micrositeId}`, {
        state: `${key + "/" + contactUSkeys.sales}`,
      });
    }
    else if (key === TAB_KEYS.aboutUs) {
      navigate(`/${micrositeId}`, {
        state: `${key + "/" + aboutUSkeys.About}`,
      });
    }
    else {
      navigate(`/${micrositeId}`, { state: `${key}` });
    }
    getSingleTabData(key === TAB_KEYS.contactUs ? contactUSkeys.sales : TAB_KEYS.aboutUs ? aboutUSkeys.About : key);
  };

  // const WhyWorkCheck = (tabId, data) => {
  //   let tabCheck = false;
  //   if (tabId == "why-work") {
  //     tabCheck = false;
  //   } else {
  //     tabCheck = true;
  //   }
  //   return tabCheck;
  // };

  const submit = (info) => {
    let getTabId = tabIdLength <= 1 ? tabId : splitParams[1];
    getTabId = getTabId === 'about-us' ? 'ABOUT_US' : getTabId
    const formattedData = formatData(getTabId, info[getTabId], updatedValues);
    // form.resetFields()
    if (Object.keys(formattedData).length > 0) {
      dispatch(setUpdatedValues({}));
      callMicroServices(getTabId, formattedData, form);
    } else if (getTabId === "work-experience") {
      toast.success("Data updated successfully");
    } else {
      toast.error("Nothing to update");
    }
  };

  const fieldsChange = (fields) => {
    if (fields && fields.length === 1) {
      const getNames = fields[0].name;
      const nameModified =
        getNames[0] === "aboutUs"
          ? "about-us"
          : getNames[0] === "workExperience"
            ? "work-experience"
            : getNames[0];
      var prev = updatedValues;

      const prevItems = prev[nameModified];
      if (prevItems) {
        const names = [...prevItems, getNames[1]];
        const removeDuplicates = names.filter(function (
          item,
          index,
          inputArray
        ) {
          return inputArray.indexOf(item) == index;
        });
        prev[nameModified] = removeDuplicates;
      } else {
        prev[nameModified] = [getNames[1]];
      }


      // if(getAllField && getAllField.length > 0){
      //   const updatedFields = getAllField.map(name => ({ name, errors: [], ...name }));

      // // form.resetFields();

      // // form.setFields({fields : updatedFields});
      // }
      dispatch(setUpdatedValues(prev));
    }
  };

  const handleOk = () => {
    setUpdatedValues(null);
    callNavigation(nextTabKey);
    setActiveKey(contactEnumsArr.includes(nextTabKey) ? "contact-us"
      : aboutUsEnumsArr.includes(nextTabKey) ? "about-us" : nextTabKey
    );
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setNextTabKey(null);
  };

  const showTabHandler = (settings, tab) => {
    if (
      tab.tabName == "Home carousel" ||
      tab.tabName == "About Us" ||
      tab.tabName == "Contact Us" ||
      tab.tabName == "Business Card"
    ) {
      return false;
    }

    if (
      settings[tab.settingsKey] === false ||
      settings[tab.settingsKey] === undefined
    )
      return true;

    return false;
  };

  const onClickArrow = (direction) => {
    const modifiedTabLists = tabList.filter(
      (d) =>
        !(d.settingsKey in settings) ||
        (d.settingsKey in settings && settings[d.settingsKey] !== false)
    );
    const getIndex = modifiedTabLists.findIndex((d) => d.tabKey == activeKey);

    if (getIndex !== -1) {
      if (direction === "right" && modifiedTabLists[getIndex + 1].tabKey) {
        setActiveKey(modifiedTabLists[getIndex + 1].tabKey);
        handleTabs(modifiedTabLists[getIndex + 1].tabKey);
      } else if (
        direction === "left" &&
        modifiedTabLists[getIndex - 1].tabKey
      ) {
        setActiveKey(modifiedTabLists[getIndex - 1].tabKey);
        handleTabs(modifiedTabLists[getIndex - 1].tabKey);
      }
    }
  };

  return (
    <React.Fragment>
      {activeKey ?
        <div className="tabHeadContainer">
          <Form
            form={form}
            autoComplete="off"
            name={TAB_KEYS.corousel}
            onFinish={submit}
            onFieldsChange={fieldsChange}
          >
            <Tabs
              type="card"
              className="tabBodyContainer"
              onChange={handleTabs}
              activeKey={activeKey}
              tabBarExtraContent={
                <div style={{ display: "flex", alignContent: "center" }}>
                  <div
                    className="leftArrow"
                    onClick={() => onClickArrow("left")}
                  // style={{ position: "absolute", left: "0px", height: "100%", backgroundColor: "white" }}
                  >
                    <img
                      className="arrow-nav-btn"
                      src={require("../../../assets/images/Path3954.png")}
                      alt="main logo"
                      width="auto"
                    />
                    <img
                      className="arrow-nav-btn"
                      src={require("../../../assets/images/Path3954.png")}
                      alt="main logo"
                      width="auto"
                    />
                  </div>
                  <div
                    className="rightArrow"
                    onClick={() => onClickArrow("right")}
                  >
                    <img
                      className="arrow-nav-btn"
                      src={require("../../../assets/images/Path3900.png")}
                      alt="main logo"
                      width="auto"
                    />
                    <img
                      className="arrow-nav-btn"
                      src={require("../../../assets/images/Path3900.png")}
                      alt="main logo"
                      width="auto"
                    />
                  </div>
                </div>
              }
            >

              {tabList.map((tab) => {
                return settings ? (
                  showTabHandler(settings, tab) ? (
                    <></>
                  ) : (
                    <Tabs.TabPane
                      className="contactUsContent"
                      tab={tab.tabName}
                      key={tab.tabKey}
                    >
                      <div className="scrollable-tab-content">
                        {tab.tabKey === TAB_KEYS.contactUs || tab.tabKey === TAB_KEYS.aboutUs
                          ? tab.component({
                            dataLoader,
                            form,
                            handleTabs,
                            splitParams,
                            activeKey,
                          })
                          : tab.component({
                            dataLoader,
                            form,
                            handleTabs,
                            activeKey,
                            getSingleTabData
                          })}
                      </div>
                    </Tabs.TabPane>
                  )
                ) : (
                  <Tabs.TabPane
                    className="contactUsContent"
                    tab={tab.tabName}
                    key={tab.tabKey}
                  >
                    <div className="scrollable-tab-content">
                      {tab.tabKey === TAB_KEYS.contactUs
                        ? tab.component({
                          dataLoader,
                          form,
                          handleTabs,
                          splitParams,
                          activeKey,
                        })
                        : tab.component({
                          dataLoader,
                          form,
                          handleTabs,
                          activeKey,
                        })}
                    </div>
                  </Tabs.TabPane>
                );
              })}

            </Tabs>

            <div className="submit-section-bottom">
              <SubmitButton postLoader={postLoader} />

            </div>

          </Form>
          <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  form.submit();
                  setTimeout(() => {
                    handleOk();
                  }, 3000);
                }}
              >
                Save
              </Button>,
              <Button type="primary" onClick={handleOk}>
                Move
              </Button>,
            ]}
          >
            <div className="tab-changes">Save and move to another tab</div>
          </Modal>
        </div> : null}
    </React.Fragment>
  );
}

export default EditMicrosite;
